.zero {
  padding-left: 2rem;
  margin-left: -2rem;
}

.last {
  padding-right: 2rem;
  margin-right: -2rem;
}

.slider :global(.swiper-slide) {
  width: auto;
}

.slider :global(.swiper-slide) + :global(.swiper-slide){
  margin-left: var(--slider-space-between, 0);
}
